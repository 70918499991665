<template>
  <div
    class="toggle"
    :class="{ 'active': state, disabled, small }"
    @click.prevent="onClick"
  >
    <div class="toggle__icon">
      <icon
        v-if="iconDisabled ? state : true"
        :image="icon"
      />
      <div
        v-if="!state && !iconDisabled"
        class="toggle__icon-disabled-overlay"
      />
      <icon
        v-if="iconDisabled && !state"
        class="toggle__icon-disabled"
        :image="iconDisabled"
      />
    </div>
    <div
      v-if="!hideBar"
      class="toggle__container"
    >
      <div
        class="toggle__draggable"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import Icon from '@/components/common/Icon';

export default {
  name: 'Toggle',

  components: {
    Icon,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideBar: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconDisabled: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    state: false,
  }),

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.state = val;
      },
    },
  },

  mounted() {
    this.toggle(this.value);
  },

  methods: {

    onClick() {
      if (this.disabled) return;
      this.toggle(!this.state);
    },

    toggle(state) {
      this.$emit('input', state);
      if (this.value !== state) this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>

.toggle {
  cursor: pointer;
  display: flex;
  align-items: center;

  &.disabled {
    opacity: 0.5;
  }

  &__container {
    position: relative;
    width: 58px;
    height: 24px;
    border-radius: 50px;
    padding: 5px 2px;
    background: var(--toggle-bg-color);
    transition: background-color 0.8s;
    margin: 0 .5rem;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.03);

    .active > & {
      background: var(--toggle-active-color);
      transition: background-color 0.8s;
    }
  }

  &__draggable {
    position: absolute;
    width: 14px;
    height: 14px;
    background: var(--toggle-draggable-color);
    border-radius: 100%;
    left: 5px;
    transition: all 0.1s ease-in-out;

    .active & {
      left: calc(100% - 14px - 5px);
    }
  }

  &.small &__container {
    width: 36px;
    height: 18px;
    padding: 3px 2px;
  }

  &.small &__draggable {
    width: 12px;
    height: 12px;
    left: 4px;
  }

  &.small.active &__draggable {
    left: calc(100% - 12px - 4px);
  }

  &__icon {
    cursor: pointer;
    position: relative;

    &-disabled-overlay {
      position: absolute;
      border-left: 2px solid white;
      display: block;
      height: 100%;
      width: 100%;
      left: 2px;
      top: 2px;
      transform: translate(25%,25%) rotate(45deg);
    }
  }
}

</style>
