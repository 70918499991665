<template lang="html">
  <header class="header">
    <div class="header__title-container">
      <icon
        image="logo"
        class="header__logo"
      />
      <div v-if="isWebinar">
        {{ safeGet(room, 'name') }}
      </div>
    </div>

    <action-nav
      v-if="isWebinar"
      :active-item="sidebarOpen ? sidebarActiveTab : null"
      class="actionnav--header"
      @select="onSelectNavItem"
    />
  </header>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  name: 'Header',

  components: {
    Icon,
  },

  data: () => ({
    error: '',
  }),

  computed: {
    ...mapState('AWCMedia', ['isMediaSupported']),
    ...mapState(['participation', 'room', 'nickname', 'sidebarOpen', 'sidebarActiveTab']),
    ...mapGetters('AWCContacts', ['me']),
    ...mapState('AWCView', ['showHardwareSettings']),
    ...mapGetters(['canSendVideo', 'canSendAudio',
      'canChangeName', 'attendeeVideoPermissions', 'attendeeAudioPermissions',
    ]),

    isWebinar() {
      return this.$route.name === 'Webinar';
    },
  },

  methods: {
    ...mapMutations(['setSidebarOpen', 'setSidebarActiveTab']),

    onSelectNavItem(key) {
      if (this.$mq !== 'mobile') return;

      if (this.sidebarOpen && this.sidebarActiveTab === key) {
        this.setSidebarOpen(false);
        return;
      }
      this.setSidebarActiveTab(key);
      if (!this.sidebarOpen) this.setSidebarOpen(true);
    },
  },
};
</script>
