<template>
  <div
    v-if="shouldShowDisclaimer"
    class="disclaimer"
  >
    <p>{{ $t('entry_disclaimer') }}</p>
  </div>
</template>

<script>
export default ({
  computed: {
    shouldShowDisclaimer() {
      return process.env.VUE_APP_SHOW_DISCLAIMER === 'true';
    },
  },
});
</script>
