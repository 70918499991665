<template>
  <div
    id="app"
    :class="[$route.meta.layout, { 'attendee': isAtendeeLayout }]"
    class="avinar"
  >
    <div ref="scriptContainer" />
    <app-header v-if="showHeader || $route.meta.header" />

    <router-view />

    <app-footer
      v-if="showFooter && $route.meta.footer"
      @modal="openModal"
    />

    <app-disclaimer />

    <!-- fallback cmp legacy -->
    <cookie-manager
      v-if="useLegacyCMP"
      :cookie-key="cookiesKey"
      :show-cookie-settings="showCookieSettingsModal"
      :delay="1000"
      @close="closeCookieManager"
    />

    <transition name="modal">
      <modal
        v-if="showImpressumModal"
        @close="showImpressumModal = false"
      >
        <div slot="header">
          {{ $t('legal.header') }}
        </div>
        <impressum />
      </modal>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppHeader from '@/components/common/Header';
import AppFooter from '@/components/common/Footer';
import AppDisclaimer from '@/components/common/Disclaimer';
import CookieManager from '@/components/cookiemanager/CookieManager';
import Impressum from '@/pages/Impressum';
import Modal from '@/components/common/Modal';

import {
  createOneTrustCookieScript,
  createOneTrustCookieScriptConsent,
  parseCookies,
} from './util/cookies';

export const cookiesKey = 'iqp_cookies';

export default {
  name: 'App',

  components: {
    AppHeader,
    AppFooter,
    AppDisclaimer,
    CookieManager,
    Impressum,
    Modal,
  },

  data: () => ({
    showCookieSettingsModal: false,
    showImpressumModal: false,
    cookiesKey,

    oneTrustCookieID: process.env.VUE_APP_ONETRUST_COOKIE_ID,
    // cookiemanager
    useLegacyCMP: !process.env.VUE_APP_ONETRUST_COOKIE_ID,

    checkTechnicalCookiesIntervalId: null,
    isTechnicalCookieAccepted: false,
    isAdvCookieAccepted: false,
  }),

  computed: {
    ...mapGetters(['showHeader', 'showFooter', 'isPresenter']),

    isAtendeeLayout() {
      return !this.isPresenter;
    },
  },

  created() {
    const isTouchDevice = 'ontouchstart' in document.documentElement;
    if (isTouchDevice) document.body.classList.add('touch');

    this.setViewportHeight();
    window.addEventListener('resize', this.setViewportHeight);
  },

  mounted() {
    this.createScripts();
    console.log('>>>', process.env.NODE_ENV, this.$gtm.enabled());
    if (process.env.NODE_ENV === 'development' && this.$gtm.enabled()) {
      this.$gtm.debug(true);
    }

    if (this.useLegacyCMP) return;
    this.checkTechnicalCookiesIntervalId = window.setInterval(
      () => this.checkTechnicalCookies(),
      2000,
    );

    this.addUsetiful();
  },

  beforeUnmount() {
    window.clearInterval(this.checkTechnicalCookiesIntervalId);
  },

  destroyed() {
    window.removeEventListener('resize', this.setViewportHeight);
  },

  methods: {
    setViewportHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },

    closeCookieManager() {
      this.showCookieSettingsModal = false;
      this.showImpressumModal = false;
    },

    handleImpressumOpen() {
      this.showCookieSettingsModal = false;
      this.showImpressumModal = true;
    },

    addUsetiful() {
      if (!process.env.VUE_APP_USETIFUL_SRC || !process.env.VUE_APP_USETIFUL_TOKEN) return;
      if (!this.$refs.scriptContainer) return;
      const script = document.createElement('script');
      script.async = 1;
      script.src = process.env.VUE_APP_USETIFUL_SRC;
      script.id = 'usetifulScript';
      script.dataset.token = process.env.VUE_APP_USETIFUL_TOKEN;
      this.$refs.scriptContainer.appendChild(script);
    },

    checkTechnicalCookies() {
      const {
        dw_Advertisement_cookie: advertisementCookieValue,
        dw_Technical_cookie: technicalCookieValue,
      } = parseCookies();

      if (advertisementCookieValue === 'opt-in' && !this.isAdvCookieAccepted) {
        if (window.dataLayer) window.dataLayer.push({ event: 'consentoptin' });
        this.isAdvCookieAccepted = true;
      }
      if (advertisementCookieValue === 'opt-out' && this.isAdvCookieAccepted) {
        this.isAdvCookieAccepted = false;
      }

      if (technicalCookieValue === 'opt-in' && !this.isTechnicalCookieAccepted) {
        if (this.$gtag) this.$gtag.optIn();
        if (this.$matomo) {
          this.$matomo.forgetUserOptOut();
          this.$matomo.rememberConsentGiven();
        }
        this.isTechnicalCookieAccepted = true;
      }
      if (technicalCookieValue === 'opt-out' && this.isTechnicalCookieAccepted) {
        this.isTechnicalCookieAccepted = false;
        if (this.$gtag) this.$gtag.optOut();
        if (this.$matomo) this.$matomo.optUserOut();
      }
    },

    createScripts() {
      if (this.oneTrustCookieID) {
        createOneTrustCookieScript(this.oneTrustCookieID, document);
        createOneTrustCookieScriptConsent(this.oneTrustCookieID, document);
      }
    },

    handleCookieSettingsOpen() {
      this.showImpressumModal = false;
      if (!this.useLegacyCMP) return;
      this.showCookieSettingsModal = true;
    },

    openModal(modal) {
      if (modal === 'impressum') this.handleImpressumOpen();
      else if (modal === 'cookies') this.handleCookieSettingsOpen();
    },
  },
};
</script>
