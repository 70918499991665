<template>
  <div class="outer">
    <div class="cookie-overlay">
      <p v-html="$t('cookiemanager.overlay_text')" />
      <div class="options">
        <app-button
          :id="getGtmId('cookiemanager_cookie_accept')"
          arrow
          type="secondary"
          :label="$t('cookiemanager.overlay_accept_label')"
          class="options__btn options__btn--accept"
          @click="$emit('accept')"
        />
        <app-button
          :id="getGtmId('cookiemanager_cookie_settings')"
          :label="$t('cookiemanager.overlay_more_label')"
          class="options__btn alt"
          @click="$emit('cookie-settings')"
        />
      </div>
      <div class="more-options">
        <a
          :id="getGtmId('cookiemanager_show_dse')"
          class="link"
          href="#"
          @click.prevent="showDSE"
        >{{ $t('cookiemanager.overlay_privacy_link_label') }}</a> &nbsp;
        <a
          :id="getGtmId('cookiemanager_click_impressum')"
          class="link"
          href="#"
          @click.prevent="clickImpressum"
        >{{ $t('cookiemanager.overlay_legal_link_label') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/Button';

export default {
  name: 'CookiesOverlay',

  components: {
    AppButton,
  },

  methods: {
    showDSE() {
      window.open(this.$t('cookiemanager.settings_privacy_link_href'), '_blank');
    },

    clickImpressum() {
      this.$emit('impressum');
    },
  },
};
</script>

<style lang="scss">

.outer {
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  overflow: hidden;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.79);
}
</style>
