<template>
  <div :class="['accordion', { open, full }]">
    <div
      class="accordion__header"
      @click="open = !open"
    >
      <slot name="header" />
      <div class="accordion__header-arrow" />
    </div>

    <transition name="slide-up-down">
      <div v-if="open">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'Accordion',

  props: {
    full: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    open: false,
  }),

};

</script>
