import { logError } from '@/util/logger';

export const themeStyles = () => {
  try {
    // eslint-disable-next-line
    require('@theme-dir/assets/scss/_app.scss');
  } catch {
    logError(`theme: styles not found for ${process.env.VUE_APP_THEME}`);
  }
};


export const setFavicon = () => {
  try {
    // eslint-disable-next-line
    const favicon = require('@theme-dir/assets/img/icons/favicon.png');
    const link = document.createElement('link');
    const lastLink = document.querySelector('link[rel*=\'icon\']');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = favicon;
    if (lastLink) document.head.removeChild(lastLink);
    document.getElementsByTagName('head')[0].appendChild(link);
  } catch {
    logError(`theme: favicon.png not found for ${process.env.VUE_APP_THEME}`);
  }
};

// @TODO: Check why some styles are not applied to when setCustmNavigation is called
export const setCustomNavigation = router => {
  try {
    // Expects an array of routes
    // eslint-disable-next-line
    const customRouter = require('@theme-dir/router').default;
    const routerCopy = [ ...router ];
    const standardRoutes = routerCopy.reduce((acc, route, i) => {
      acc.set(route.path, { i, ...route });
      return acc;
    }, new Map());
    
    // eslint-disable-next-line
    for (let i = 0; i < customRouter.length; i++) {
      const customRoute = customRouter[i];
      if (!standardRoutes.has(customRoute.path)) {
        routerCopy.push(customRoute);
      } else {
        const standardRoute = standardRoutes.get(customRoute.path);
        const { i: index } = standardRoute;
        delete standardRoute.i;
        routerCopy[index] = { ...customRoute };
      }
    }

    return routerCopy;
  } catch (_) {
    return router;
  }
}
