import Vue from 'vue';
import Vuex from 'vuex';

import get from 'lodash/get';
import createPersistedState from 'vuex-persistedstate';
import request from '@/api/api';
import { log } from '@/util/logger';


Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    // Current room information, gotten from /api/enlist.
    room: null,
    // Current participant information, gotten from /api/enlist.
    participation: null,
    // Validation token used for the "Gost page"
    validationToken: null,
    // Public JWT used to indicate the room to join.
    // We should keep this unless window closes.
    enlistToken: null,
    // JWT that contains the current Participants' data.
    // We should keep this until user hangs up or closes window.
    participationToken: null,
    unreadMessages: 0,
    sidebarOpen: false,
    sidebarActiveTab: '',
    attendeeToBan: false,
    nicknameConfirmed: false,
    // If true, the top header with logo and footer will not be shown.
    isEmbeddedVersion: false,
  },

  plugins: [
    createPersistedState({
      paths: [
        'participationToken',
        'participation',
        'room',
        'audioEnabled',
        'videoEnabled',
        'enlistToken',
        'nicknameConfirmed',
      ],
    }),
  ],

  getters: {
    roomStarted: state => get(state, 'room.status') !== 'CREATED',
    isPresenter: state => get(state, 'participation.role') === 'PRESENTER',
    nickname: state => get(state, 'participation.nickname'),
    participationId: state => get(state, 'participation.participationId'),
    webinarLoaded: state => !!state.participation && !!state.room,
    attendeeAudioPermissions: state => (get(state, 'room.permissionsAttendee') || []).includes('CAN_SEND_AUDIO'),
    attendeeVideoPermissions: state => (get(state, 'room.permissionsAttendee') || []).includes('CAN_SEND_VIDEO'),
    videosActive: state => get(state, 'room.settings.videosActive'),
    introVideo: state => get(state, 'room.videos', []).find(item => item.type === 'INTRO'),
    roomSettings: state => {
      if (state.room) return state.room.settings || false;
      return false;
    },

    playoutId: state => get(state, 'room.settings.playoutId'),

    canSendVideo: state => get(state, 'participation.permissions', []).includes('CAN_SEND_VIDEO'),
    canSendAudio: state => get(state, 'participation.permissions', []).includes('CAN_SEND_AUDIO'),
    canChangeName: state => get(state, 'participation.permissions', []).includes('CAN_SET_NICKNAME'),

    showBanModal: state => !!state.attendeeToBan,

    // global setting, cannot be changed
    videoSupported: (_, getters) => (getters.isPresenter && getters.canSendVideo)
      || getters.attendeeVideoPermissions,
    audioSupported: (_, getters) => (getters.isPresenter && getters.canSendAudio)
      || getters.attendeeAudioPermissions,

    // global setting that influences if header and footer are shown.
    isEmbeddedVersion: state => state.isEmbeddedVersion,
    // global header display setting.
    showHeader: (_, getters) => !getters.isEmbeddedVersion,
    // global footer display setting.
    showFooter: (_, getters) => !getters.isEmbeddedVersion,
  },

  mutations: {
    setRoom: (state, room) => state.room = room,
    setUnreadMessages: (state, count) => state.unreadMessages = count,
    setRoomSettings: (state, settings) => state.room.settings = settings,
    setValidation: (state, validation) => state.validationToken = validation,
    setParticipation: (state, participation) => state.participation = participation,
    setAttendeeToBan: (state, participationId = false) => state.attendeeToBan = participationId,
    updateParticipation: (state, newParticipation) => { Object.assign(state.participation, newParticipation); },
    setParticipationToken: (state, participationToken) => state.participationToken = participationToken,
    setEnlistToken: (state, token) => state.enlistToken = token,
    setSidebarOpen: (state, sidebarOpen) => state.sidebarOpen = sidebarOpen,
    setSidebarActiveTab: (state, sidebarActiveTab) => state.sidebarActiveTab = sidebarActiveTab,
    setShowHardwareTestModal: (state, showModal) => state.showHardwareTestModal = showModal,
    setNickname: (state, nickname) => state.participation.nickname = nickname,
    setNicknameConfirmed: (state, nicknameConfirmed) => state.nicknameConfirmed = nicknameConfirmed,
    setIsEmbeddedVersion: (state, isEmbeddedVersion) => state.isEmbeddedVersion = isEmbeddedVersion,
  },

  actions: {
    async enlistParticipation({ commit }, { token }) {
      log('Store: enlistParticipation', token);
      const { data } = await request.enlistParticipation(token);

      const { room, participation, participation: { participationToken } } = data;
      if (!participation || !room) throw new Error('Webinar not found');

      commit('setRoom', room);
      commit('setParticipation', participation);
      commit('setParticipationToken', participationToken);
    },

    async clearConferenceData({ commit }) {
      commit('setRoom', null);
      commit('setParticipation', null);
      commit('setParticipationToken', null);
    },

    async updateParticipation({ commit, state }, data) {
      log('Store: updateParticipation', data);
      if (!state.participationToken) throw new Error('Token not found');

      const { data: participation } = await request.updateParticipation(state.participationToken, data);

      if (!participation) throw new Error('Webinar not found');

      commit('setParticipation', participation);
    },

    removeParticipation({ commit, state }) {
      log('Store: Reseting participation...');
      state.room = null;
      state.unreadMessages = 0;
      state.sidebarOpen = false;
      state.participation = null;
      state.participationToken = null;
      state.nicknameConfirmed = false;
      log('Store: Participation reset.');
      commit('AWCUser/resetUser');
    },
  },
});
