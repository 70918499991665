<template>
  <div class="footer">
    <div class="footer__top">
      <a
        v-for="(link, idx) in footerLinks"
        :id="getGtmId(link.label)"
        :key="idx"
        class="footer__link"
        @click="!link.modal ? linkTo(link.href, link.target) : $emit('modal', link.modal)"
      >
        {{ $t(link.label) }}
      </a>
      <a
        :id="getGtmId('footer_link_cookies')"
        class="ot-sdk-show-settings footer__link"
        @click="$emit('cookies')"
      >
        {{ $t('cookies_link_label') }}
      </a>
    </div>
    <div
      v-if="$t('footer_copy')"
      class="footer__sub"
    >
      <div class="footer__sub-inner">
        {{ $t('footer_copy') }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Footer',

  computed: {
    footerLinks() {
      return this.safeGet(APP_CONFIG, 'footer.links');
    },
  },

  methods: {
    linkTo(link, target) {
      window.open(link, target);
    },
  },
};
</script>
