<template>
  <component
    :is="icon"
    v-if="image"
    :class="`svg-icon svg-icon--size-${size}`"
  />
</template>

<script>

export default {
  name: 'Icon',
  props: {
    image: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    icon() {
      try {
        // eslint-disable-next-line
        return require(`@theme-dir/assets/img/icons/${this.image}.svg`).default;
      } catch (e) {
        // eslint-disable-next-line
        return require(`@/assets/img/icons/${this.image}.svg`).default;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  max-width: 100%;
  max-height: 100%;

  &--size-xsmall {
    height: $icon-size--xsmall;
    width: $icon-size--xsmall;
  }

  &--size-small {
    height: $icon-size--small;
    width: $icon-size--small;
  }

  &--size-medium {
    height: $icon-size--medium;
    width: $icon-size--medium;
  }

  &--size-large {
    height: $icon-size--large;
    width: $icon-size--large;
  }

  &--size-xlarge {
    height: $icon-size--xlarge;
    width: $icon-size--xlarge;
  }

  &--size-custom {
    width: 26px;
    height: 26px;
  }
}
</style>
