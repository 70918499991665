import i18n from '@/util/i18n';

const ThankYou = () => import('@/pages/ThankYou');

const router = [
  {
    path: '/thankyou',
    component: ThankYou,
    name: 'ThankYou',
    meta: { footer: false, layout: 'info', participation: false, title: i18n.t('thank_you_for_joining') },
    props: true,
  },
];

export default router;
