import { mapGetters } from 'vuex';
import requests from '@/api/api';
import { logError, log } from '@/util/logger';
import getCurrentTimeAsString from '@/util/time';

const trackAt = {
  10: {
    sent: false,
    secs: 600,
  },
  15: {
    sent: false,
    secs: 900,
  },
  20: {
    sent: false,
    secs: 1200,
  },
};

export default {
  name: 'Tracking',

  data: () => ({
    joinTime: 0,
    leaveTime: 0,
    leaveAfter: 0,
    intervalId: null,
  }),

  created() {
    window.dataLayer = window.dataLayer || [];
  },

  computed: {
    ...mapGetters('AWCContacts', ['mappedContacts']),
  },

  methods: {
    trackEvent(category, action, name, value) {
      if (this.$matomo) this.$matomo.trackEvent(category, action, name, value);
    },

    trackJoin() {
      this.joinTime = new Date();
      if (this.$matomo) this.trackEvent('Conference', 'join', this.$store.state.room.name, this.joinTime.getTime());
      if (this.gtmData.ready) {
        log('gtmTrackJoin');
        window.dataLayer.push({
          ...this.getDefaultEvent(),
          event: 'room_join',
          ga_clientID: this.gtmData.gtmClientId,
        });

        this.intervalId = setInterval(this.trackPermanence, 60000);
      }
    },

    trackLeave() {
      this.leaveTime = new Date();
      this.leaveAfter = (this.leaveTime - this.joinTime) / 1000;
      if (this.$matomo) {
        this.trackEvent('Conference', 'leave', this.$store.state.room.name, this.leaveTime.getTime());
        this.trackEvent('Conference', 'total participation time (in seconds)', this.$store.state.room.name, this.leaveAfter);
      }

      if (this.gtmData.ready) {
        log('gtmTrackLeave');
        window.dataLayer.push({
          ...this.getDefaultEvent(),
          event: 'room_exit',
          ga_clientID: this.gtmData.gtmClientId,
          attendance_time: this.leaveAfter,
        });

        clearInterval(this.intervalId);
      }
    },

    trackPermanence() {
      const currentAttendanceTime = (new Date() - this.joinTime) / 1000;
      if (this.gtmData.ready) {
        log('gtmTrackPermanence');
        window.dataLayer.push({
          ...this.getDefaultEvent(),
          event: 'room_active',
          ga_clientID: this.gtmData.gtmClientId,
          attendance_time: currentAttendanceTime,
        });

        const min = Math.floor(currentAttendanceTime / 60).toFixed(0);
        if (
          this.$store.state.participation.role !== 'PRESENTER'
          && Object.keys(trackAt).includes(min)
          && !trackAt[min].sent
        ) {
          log('gtmTrackPermanenceFire', min);
          requests.publishPermanence(
            this.$store.state.participationToken,
            trackAt[min].secs,
          )
            .then(() => trackAt[min].sent = true)
            .catch(err => logError(err));
        }
      }
    },

    /**
     * Tracks a generic event in GTM by pushing to dataLayer.
     *
     * @param { string } event
     * @param { any } data
     */
    trackAction(event, data = {}) {
      const currentAttendanceTime = (new Date() - this.joinTime) / 1000;
      if (this.gtmData.ready) {
        log('gtmTrackGenericAction');
        window.dataLayer.push({
          ...this.getDefaultEvent(),
          event,
          ga_clientID: this.gtmData.gtmClientId,
          attendance_time: currentAttendanceTime,
          ...data,
        });
      }
    },

    /**
     * Returns a shape of Event that matches the Participant's role.
     *
     * @returns { object } defaultEvent.active_users?
     */
    getDefaultEvent() {
      const defaultEvent = {
        room_name: this.$store.state.room.name,
        room_id: this.$store.state.room.roomId,
        time_stamp: getCurrentTimeAsString(),
        user_type: this.$store.state.participation.role,
        attendance_time: 0,
      };

      return {
        ...defaultEvent,
        active_users: this.$store.state.participation.role === 'PRESENTER'
          ? this.mappedContacts.length + 1 : undefined,
      };
    },
  },
};
