<template>
  <div
    class="modal-wrapper"
    :class="{ 'modal-wrapper--dark': dark }"
    @click="backdropClose ? close() : null"
  >
    <div
      class="modal__container"
      :class="[`modal__container--${width}`]"
    >
      <div
        class="modal"
        :class="[`modal--${width}`, { transparent, centered }]"
      >
        <div
          v-if="closable"
          class="modal__close"
          @click="close()"
        >
          <icon image="close" />
        </div>
        <div
          class="modal__inner"
          @click.stop.prevent
        >
          <icon
            v-if="icon"
            :image="icon"
            class="modal__icon"
          />

          <div class="modal__header">
            <slot name="header" />
          </div>

          <div class="modal__body">
            <slot />

            <div
              v-if="confirmLabel"
              class="modal__buttons"
            >
              <app-button
                :id="getGtmId('modal_confirm')"
                :label="confirmLabel"
                type="primary"
                :arrow="buttonArrow"
                @click="confirm()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  name: 'Modal',

  components: {
    AppButton,
    Icon,
  },

  props: {

    confirmLabel: {
      type: String,
      default: '',
    },

    buttonArrow: {
      type: Boolean,
      default: true,
    },

    icon: {
      type: String,
      default: '',
    },

    width: {
      type: String,
      default: 'medium',
    },

    closable: {
      type: Boolean,
      default: true,
    },

    backdropClose: {
      type: Boolean,
      default: false,
    },

    transparent: {
      type: Boolean,
      default: false,
    },

    centered: {
      type: Boolean,
      default: false,
    },

    dark: {
      type: Boolean,
      default: false,
    },
  },

  methods: {

    close() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
